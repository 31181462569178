import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import CustomImage from "../../atoms/customImage";
import { appendDots } from "../carouselHelpers";
import { PRODUCT_CARD_OPTIONS } from "../../../constants/common/splideOptions";

/**
 * A slider component that displays a list of product images.
 * It supports autoplay functionality, which can be controlled by the parent component.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string[]} props.productImgList - An array of image URLs to display in the slider.
 * @param {number} props.imgWidth - The width of each image in the slider.
 * @param {number} props.imgHeight - The height of each image in the slider.
 * @param {string} props.alt - The alt text for each image in the slider.
 * @param {string} props.defaultImg - The default image URL to display when `productImgList` is empty.
 * @param {boolean} props.isPriority - Whether the images should be prioritized for loading.
 * @param {boolean} props.isMounted - check client or server rendering.
 * @param {React.Ref} ref - A ref object that allows the parent component to control the slider (e.g., start or stop autoplay).
 *
 * @returns {JSX.Element} The rendered slider component.
 */
const ProductCardImageSlider = forwardRef(
  ({ productImgList = [], imgWidth, imgHeight, alt, defaultImg, isPriority = false, isMounted }, ref) => {
    const [autoplay, setAutoplay] = useState(false);
    const sliderRef = useRef(null);

    useImperativeHandle(ref, () => ({
      /**
       * Starts the autoplay for the image slider.
       */
      startAutoplay() {
        setAutoplay(true);
      },
      /**
       * Stops the autoplay for the image slider and resets it to the first slide.
       */
      stopAutoplay() {
        setAutoplay(false);
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(0); // Reset the slider to the first slide
        }
      },
    }));

    useEffect(() => {
      if (sliderRef.current) {
        if (autoplay) {
          sliderRef.current.slickPlay();
        } else {
          sliderRef.current.slickPause();
        }
      }
    }, [autoplay]);

    const settings = {
      ...PRODUCT_CARD_OPTIONS,
      dots: productImgList.length > 1,
      autoplay,
      appendDots: (dots) => appendDots(dots, "", "", "", "", "productCardDots"),
    };
    return (
      <>
        {!isMounted && productImgList.length >= 0 ? (
          <CustomImage
            src={defaultImg}
            width={imgWidth}
            height={imgHeight}
            alt={alt}
            isPriority={isPriority}
            customStyle={{ width: "100%", height: "100%" }}
            itemProp="image"
            sizes="(max-width: 768px) 100vw, 50vw"
          />
        ) : (
          <Slider {...settings} ref={sliderRef} className="respective w-full">
            {productImgList.map((item, index) => (
              <CustomImage
                key={index}
                src={item}
                width={imgWidth}
                height={imgHeight}
                alt={alt}
                isPriority={isPriority && index === 0}
                customStyle={{ width: "100%", height: "100%" }}
                itemProp="image"
                sizes="(max-width: 768px) 100vw, 50vw"
              />
            ))}
          </Slider>
        )}
      </>
    );
  },
);

ProductCardImageSlider.displayName = "ProductCardImageSlider";

ProductCardImageSlider.propTypes = {
  productImgList: PropTypes.arrayOf(PropTypes.string),
  imgWidth: PropTypes.number.isRequired,
  imgHeight: PropTypes.number.isRequired,
  alt: PropTypes.string.isRequired,
  defaultImg: PropTypes.string,
  isPriority: PropTypes.bool,
  isMounted: PropTypes.bool,
};

export default ProductCardImageSlider;
